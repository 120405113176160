/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
  }

  
.ClassCenter{
    text-align: center;
}

.ClassBold{
   font-weight: bold !important;
}

.ClassFloatLeft{
    float: left;
}

.ClassFloatRight{
    float: right;
}

.ClassAterisco{
    font-size: 20px;
    font-weight: bold;
    color: #f12424;
}

.ClassPointer{
        cursor: pointer;
        
}


//nota:  .alert-wrapper hace referencia al dialogo en si.
// BOTON AGREGAR PRODUCTO +
.alert-wrapper .alert-button-role-aceptar{
    background: #28bd90;
    color: white;
    border-radius: 10px;
    padding: 8px;
}


//BOTON CANCELAR
.my-custom-class .alert-wrapper .alert-button-role-destructive {
    background: #e44545a6;
    color: white;
    font-weight: bold;
    border-radius: 8px;

        // width: 700px;
  }



//  LETRAS DEL CAMPO CANTIDAD
  .my-custom-class .alert-wrapper  {
    // font-weight: bold;
    font-size: 25px;
  }